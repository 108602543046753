import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import LandingDashboard from "@/landing/views/LandingDashboard.vue";
import LandingLogin from "@/landing/views/LandingLogin.vue";

export enum RouteNames {
  Dashboard = 'LandingDashboard',
  Login = "LandingLogin"
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/landing',
    name: RouteNames.Dashboard,
    component: LandingDashboard
  },
  {
    path: '/landing/login/:state',
    name: RouteNames.Login,
    component: LandingLogin
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router
