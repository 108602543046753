<template>
  <svg width="26" height="31" viewBox="0 0 26 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.3334 17H13.6667C14.1971 17 14.7058 16.7893 15.0809 16.4142C15.456 16.0391 15.6667 15.5304 15.6667 15C15.6667 14.4696 15.456 13.9609 15.0809 13.5858C14.7058 13.2107 14.1971 13 13.6667 13H12.3334C11.8029 13 11.2942 12.7893 10.9192 12.4142C10.5441 12.0391 10.3334 11.5304 10.3334 11C10.3334 10.4696 10.5441 9.96086 10.9192 9.58579C11.2942 9.21071 11.8029 9 12.3334 9H15.6667" stroke="#3E2CAF" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square"/>
    <path d="M13 6.3335V9.00016" stroke="#3E2CAF" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square"/>
    <path d="M13 17V19.6667" stroke="#3E2CAF" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square"/>
    <path d="M2.33333 29L5.88 26.3333L9.44 29L12.9867 26.3333L16.5467 29L20.1067 26.3333L23.6667 29L25 27.6667V1H1V27.6667L2.33333 29Z" stroke="#3E2CAF" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square"/>
  </svg>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
</style>