<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 12.0001L12 2.83008L23 12.0001" fill="#2C1C8D"/>
    <path d="M1 12.0001L12 2.83008L23 12.0001" stroke="#2C1C8D" stroke-width="1.5" stroke-miterlimit="10"/>
    <path d="M19.3299 9.25V21.17H14.7499V13.83H9.24992V21.17H4.66992V9.25" fill="#2C1C8D"/>
    <path d="M19.3299 9.25V21.17H14.7499V13.83H9.24992V21.17H4.66992V9.25" stroke="#2C1C8D" stroke-width="1.5" stroke-miterlimit="10"/>
  </svg>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
</style>