import log from 'loglevel';
import BannoWebClient from "@/data/bannoWebClient";
import { Data } from "@/data/generated/api";
import CFMBannoWebViewData = Data.CFMBannoWebViewData;

interface LogData {
  context?: CFMBannoWebViewData,
}

const logger = log.noConflict();
const loggerData: LogData = {};

const originalFactory = log.methodFactory;
log.methodFactory = function (methodName, logLevel, loggerName) {
  const rawMethod = originalFactory(methodName, logLevel, loggerName);
  return function (message) {
    if (loggerData.context?.config?.console?.remoteLog) {
      // noinspection JSIgnoredPromiseFromCall
      BannoWebClient.devLog({
        lines: [typeof message === "string" ? message : JSON.stringify(message)]
      });
    }
    rawMethod(message);
  };
};

logger.setLevel(process.env.NODE_ENV === "production" ? "error" : "debug")

export {
  logger,
  loggerData
};