<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_919_1194)">
      <path d="M17.72 11.0498C17.72 7.88523 15.1568 5.31982 11.995 5.31982C8.83319 5.31982 6.27002 7.88523 6.27002 11.0498V14.8598C6.27002 18.0244 8.83319 20.5898 11.995 20.5898C15.1568 20.5898 17.72 18.0244 17.72 14.8598V11.0498Z" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10"/>
      <path d="M17.73 9.14014H19.64C20.3985 9.14014 21.126 9.44146 21.6623 9.97781C22.1987 10.5142 22.5 11.2416 22.5 12.0001V13.9101C22.5 14.6687 22.1987 15.3961 21.6623 15.9325C21.126 16.4688 20.3985 16.7701 19.64 16.7701H17.73V9.14014Z" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10"/>
      <path d="M6.27002 16.77H4.36002C3.6015 16.77 2.87405 16.4687 2.33769 15.9323C1.80134 15.396 1.50002 14.6685 1.50002 13.91L1.50002 12C1.50002 11.6244 1.574 11.2525 1.71772 10.9055C1.86145 10.5586 2.07212 10.2433 2.33769 9.97769C2.60327 9.71212 2.91855 9.50145 3.26554 9.35772C3.61254 9.214 3.98444 9.14002 4.36002 9.14002H6.27002L6.27002 16.77Z" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10"/>
      <path d="M4.36011 9.14C4.36011 7.11375 5.16503 5.17048 6.59781 3.7377C8.03059 2.30493 9.97385 1.5 12.0001 1.5C14.0264 1.5 15.9696 2.30493 17.4024 3.7377C18.8352 5.17048 19.6401 7.11375 19.6401 9.14" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10"/>
      <path d="M19.6401 16.77V17.77C19.6375 19.036 19.1327 20.2493 18.2365 21.1435C17.3404 22.0378 16.1261 22.54 14.8601 22.54" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10"/>
    </g>
    <defs>
      <clipPath id="clip0_919_1194">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
</style>