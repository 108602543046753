<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.8134 22.3733C25.4393 22.3733 30 17.8126 30 12.1867C30 6.56073 25.4393 2 19.8134 2C14.1874 2 9.62671 6.56073 9.62671 12.1867C9.62671 17.8126 14.1874 22.3733 19.8134 22.3733Z" stroke="#3E2CAF" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M2 30.0001L12.1867 19.8135" stroke="#3E2CAF" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M16 15.9999H20.4533C20.7037 15.9999 20.9517 15.9505 21.183 15.8547C21.4143 15.7589 21.6245 15.6185 21.8015 15.4414C21.9786 15.2644 22.119 15.0542 22.2149 14.8228C22.3107 14.5915 22.36 14.3436 22.36 14.0932C22.36 13.5875 22.1591 13.1025 21.8015 12.745C21.444 12.3874 20.959 12.1865 20.4533 12.1865H19.1867C18.6891 12.1692 18.2177 11.9594 17.8719 11.6011C17.5261 11.2429 17.333 10.7644 17.3333 10.2665C17.3369 9.75963 17.5407 9.27469 17.9004 8.9175C18.2601 8.56031 18.7464 8.35985 19.2533 8.35986H23.7067" stroke="#3E2CAF" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M19.8134 5.81348V8.36014" stroke="#3E2CAF" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M19.8134 16V18.5467" stroke="#3E2CAF" stroke-width="2" stroke-miterlimit="10"/>
  </svg>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
</style>