<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0001 4.1665V15.8332M4.16675 9.99984H15.8334" stroke="#D0C9FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
</style>