<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9999 11.0198C13.5794 11.0198 14.8599 9.73934 14.8599 8.1598C14.8599 6.58027 13.5794 5.2998 11.9999 5.2998C10.4204 5.2998 9.13989 6.58027 9.13989 8.1598C9.13989 9.73934 10.4204 11.0198 11.9999 11.0198Z" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"/>
    <path d="M14.8599 18.6602L11.9999 21.5202L9.13989 18.6602" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"/>
    <path d="M12 20.57V16.75C12 16.4992 11.9506 16.2508 11.8546 16.0191C11.7586 15.7873 11.6179 15.5768 11.4406 15.3994C11.2632 15.222 11.0527 15.0814 10.8209 14.9854C10.5892 14.8894 10.3408 14.84 10.09 14.84H1.5V1.47998H22.5V14.84H15.82" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"/>
    <path d="M19.64 8.16016H17.73" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10"/>
    <path d="M6.27011 8.16016H4.36011" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10"/>
  </svg>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
</style>