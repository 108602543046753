import axios, { AxiosRequestConfig } from "axios";
import { Data } from "@/data/generated/api";
import { logger } from "@/utils/logger";
import { isDevelopment } from "@/utils/configUtils";
import { getSessionTokenFromQuery } from "@/utils/sessionUtils";
import { BASE_URI, PARAM_ID, PARAM_ORIGINATING_URI, redirect } from "@/utils/uriUtils";

const BASE_API_URI = `${BASE_URI}/api/v1`;
const PROFILE_PATH = "profile";
const USER_ACCOUNTS_PATH = "user/accounts";
const CFM_OPERATION_PATH = "operation";
const CFM_OPERATION_AUTHORIZE_PATH = "operation/authorize";
const CFM_OPERATION_CASHIERS_CHECK_PATH = "operation/cashierscheck";
const CFM_SESSION_PATH = "session";
const CFM_NOTIFICATIONS_SUBSCRIBE_PATH = "subscribe";
const CFM_NOTIFICATIONS_UNSUBSCRIBE_PATH = "unsubscribe";
const CFM_GENERATE_IMAGE_PATH = "operation/cashierscheck/image";
const CFM_DEV_LOG_PATH = "dev/log";
const LOGOUT_PATH = "logout";
const ERROR_PATH = "error";

export class ResponseError extends Error {
  static readonly DEFAULT_ERROR_DATA: Data.CFMCloudResponse = {
    cfmCode: "50011-CBI-CFM",
    displayMessage: ""
  }
  static readonly DEFAULT_STATUS_CODE = 500;

  readonly cause: Error | null;
  readonly cfmCode: Data.CFMCloudStatusCode | null;
  readonly status: number;
  readonly headers: Record<string, string>;

  constructor(
    cause: Error | null,
    data: any,
    status: number,
    message: string,
    headers: Record<string, string>
  ) {
    super(message);
    this.cause = cause;
    this.cfmCode = data?.cfmCode;
    this.status = status;
    this.headers = headers;
  }

  static fromMessage(message: string): ResponseError {
    return new ResponseError(null, this.DEFAULT_ERROR_DATA, this.DEFAULT_STATUS_CODE, message, {});
  }

  static fromCause(cause: Error): ResponseError {
    return new ResponseError(cause, this.DEFAULT_ERROR_DATA, this.DEFAULT_STATUS_CODE, cause.message, {});
  }
}

class BannoWebClient {
  private readonly baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public getBannoProfile(): Promise<Data.CFMBannoWebProfileResponse | null> {
    return this.request<Data.CFMBannoWebProfileResponse>(this.get(PROFILE_PATH));
  }

  public getBannoUserAccounts(): Promise<Data.CFMBannoWebUserAccountsResponse | null> {
    return this.request<Data.CFMBannoWebUserAccountsResponse>(this.get(USER_ACCOUNTS_PATH));
  }

  public getCfmOperations(): Promise<Data.CFMBannoWebOperationsResponse | null> {
    return this.request<Data.CFMBannoWebOperationsResponse>(this.get(CFM_OPERATION_PATH));
  }

  public refreshCfmSession(): Promise<Data.CFMBannoWebSessionResponse | null> {
    return this.request<Data.CFMBannoWebSessionResponse>(this.get(CFM_SESSION_PATH));
  }

  public authorizeCfmOperation(request: Data.CFMBannoWebAuthorizeOperationRequest): Promise<Data.CFMBannoWebAuthorizeOperationResponse | null> {
    return this.request<Data.CFMBannoWebAuthorizeOperationResponse>({
      ...this.post(CFM_OPERATION_AUTHORIZE_PATH),
      data: request
    });
  }

  public saveCfmOperation(request: Data.CFMBannoWebCashiersCheckAddRequest): Promise<Data.CFMBannoWebCashiersCheckAddResponse | null> {
    return this.request<Data.CFMBannoWebCashiersCheckAddResponse>({
      ...this.post(CFM_OPERATION_CASHIERS_CHECK_PATH),
      data: request
    });
  }

  public generateCheckImage(request: Data.CFMBannoWebCheckImageRequest): Promise<Data.CFMBannoWebCheckImageResponse | null> {
    return this.request<Data.CFMBannoWebCheckImageResponse>({
      ...this.post(CFM_GENERATE_IMAGE_PATH),
      data: request
    });
  }

  public deleteCfmOperation(operationId: string): Promise<Data.CFMBannoWebCashiersCheckDeleteResponse | null> {
    return this.request<Data.CFMBannoWebCashiersCheckDeleteResponse>({
      ...this.delete(`${CFM_OPERATION_CASHIERS_CHECK_PATH}/${operationId}`)
    });
  }

  public subscribeCfmNotifications(id: string): Promise<EventSource> {
    const url = `${this.buildUrl(CFM_NOTIFICATIONS_SUBSCRIBE_PATH)}?${PARAM_ID}=${id}`;
    logger.info(`[request] ${url}`);
    return Promise.resolve(new EventSource(url, {withCredentials: true}));
  }

  public unsubscribeCfmNotifications(id: string): Promise<Data.CFMBannoWebNotificationsUnsubscribeResponse | null> {
    return this.request<Data.CFMBannoWebNotificationsUnsubscribeResponse>({
      ...this.get(CFM_NOTIFICATIONS_UNSUBSCRIBE_PATH),
      params: {
        id: id
      }
    });
  }

  public devLog(request: Data.CFMBannoWebDevRemoteLogRequest): Promise<Data.CFMBannoWebDevRemoteLogResponse | null> {
    if (isDevelopment()) {
      const config = BannoWebClient.withCsrfToken(
        {
          url: this.buildUrl(CFM_DEV_LOG_PATH),
          method: "post"
        },
        false
      );
      if (config instanceof Error) {
        return Promise.reject(config);
      }
      return axios
        .request({
          ...config,
          data: request
        })
        .then(
          response => response.data as Data.CFMBannoWebDevRemoteLogResponse,
          error => BannoWebClient.reject(error)
        );
    } else {
      return Promise.resolve({
        cfmCode: "20001-CBI-CFM",
        displayMessage: ""
      });
    }
  }

  public redirectLogout() {
    redirect(`${BASE_URI}/${LOGOUT_PATH}`);
  }

  public redirectError() {
    redirect(`${BASE_URI}/${ERROR_PATH}?${PARAM_ORIGINATING_URI}=${encodeURIComponent(window.location.pathname)}`);
  }

  private request<T>(config: AxiosRequestConfig | Error): Promise<T | null> {
    if (config instanceof Error) {
      return Promise.reject(config);
    } else {
      return axios.request(config)
        .then(response => {
          return response.data as T;
        }, error => {
          logger.error("[response]", error.response);
          return BannoWebClient.reject(error);
        });
    }
  }

  private static reject<T>(error: any): Promise<T> {
    const response = error.response;
    return Promise.reject(new ResponseError(
      error,
      response.data,
      response.status,
      response.statusText,
      response.headers
    ));
  }

  private get(path: string): AxiosRequestConfig {
    return BannoWebClient.withAuthorization(this.buildRequest("get", path));
  }

  private post(path: string): AxiosRequestConfig | Error {
    return BannoWebClient.withCsrfToken(BannoWebClient.withAuthorization(this.buildRequest("post", path)));
  }

  private delete(path: string): AxiosRequestConfig | Error {
    return BannoWebClient.withCsrfToken(BannoWebClient.withAuthorization(this.buildRequest("delete", path)));
  }

  private static withAuthorization(options: AxiosRequestConfig): AxiosRequestConfig {
    // Cookies are automatically sent for Ajax requests, so we only need to set this
    // if cross-site cookies are blocked (and we're using a query string parameter instead).
    const sessionToken = getSessionTokenFromQuery();
    if (sessionToken) {
      return {
        ...options,
        headers: {
          ...options.headers,
          "Authorization": `Bearer ${sessionToken}`
        }
      }
    }
    return options;
  }

  public static withCsrfToken(options: AxiosRequestConfig, logToken = true): AxiosRequestConfig | Error {
    const csrfToken = document.querySelector('meta[name="_csrf"]')?.getAttribute("content");
    const csrfHeader = document.querySelector('meta[name="_csrf_header"]')?.getAttribute("content");
    if (csrfToken && csrfHeader) {
      if (logToken) {
        logger.debug(`${csrfHeader} : ${csrfToken} (${options.url})`);
      }
      return {
        ...options,
        headers: {
          ...options.headers,
          [csrfHeader]: csrfToken
        }
      }
    } else {
      return new Error("CSRF token not found");
    }
  }

  private buildRequest(method: string, path: string): AxiosRequestConfig {
    const url = this.buildUrl(path);
    logger.info(`[request] ${url}`);

    return {
      url: url,
      method: method
    };
  }

  private buildUrl(path: string): string {
    if (!path.startsWith("/")) {
      path = "/" + path;
    }
    return this.baseUrl + path;
  }
}

const DefaultBannoWebClient = new BannoWebClient(BASE_API_URI);
export default DefaultBannoWebClient;

export {
  BannoWebClient
}
