import { createApp, ref } from "vue";
import { createHead } from "@vueuse/head";
import LandingApp from "@/landing/LandingApp.vue";
import router from "@/landing/router";
import { parseCfmWebContext } from "@/utils/configUtils";
import { Data } from "@/data/generated/api";
import { CFMContext, cfmContextKey } from "@/data/context";
import { isMobileWebView, isTouchDevice, isTouchDeviceSmall } from "@/utils/mobileUtils";
import { loggerData } from "@/utils/logger";

import "@/scss/landing.scss"
import "bootstrap/dist/js/bootstrap";

const app = createApp(LandingApp)
  .use(router)
  .use(createHead());

const cfmWebContext = parseCfmWebContext<Data.CFMBannoWebViewData>(document.getElementById("cfm-web-context")?.innerHTML);
if (cfmWebContext) {
  app.provide(cfmContextKey, ref<CFMContext>({
    web: cfmWebContext,
    plugin: {
      isEmbedded: false,
      isTouchDevice: isTouchDevice(),
      isTouchDeviceSmall: isTouchDeviceSmall(),
      isMobileWebView: isMobileWebView(cfmWebContext)
    }
  }));
  loggerData.context = cfmWebContext;
}

app.mount('#app')
