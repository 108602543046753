<template>
  <svg width="366" height="188" viewBox="0 0 366 188" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.8572 5C28.8572 2.23857 31.0958 -4.12786e-09 33.8572 -9.21983e-09C36.6186 -1.43118e-08 38.8572 2.23858 38.8572 5L38.8572 159C38.8572 161.761 36.6186 164 33.8572 164C31.0958 164 28.8572 161.761 28.8572 159L28.8572 5Z" fill="url(#paint0_linear_676_6064)"/>
    <g filter="url(#filter0_d_676_6064)">
      <path d="M42.8572 109C42.8572 106.239 45.0958 104 47.8572 104C50.6186 104 52.8572 106.239 52.8572 109L52.8572 159C52.8572 161.761 50.6186 164 47.8572 164C45.0958 164 42.8572 161.761 42.8572 159L42.8572 109Z" fill="url(#paint1_linear_676_6064)" shape-rendering="crispEdges"/>
    </g>
    <path d="M33.2139 171.273H34.5264L34.5264 177.46C34.5264 178.02 34.4156 178.499 34.194 178.896C33.9753 179.294 33.667 179.598 33.2693 179.808C32.8716 180.016 32.4057 180.119 31.8716 180.119C31.3801 180.119 30.9383 180.03 30.5463 179.851C30.1571 179.672 29.8488 179.412 29.6216 179.071C29.3971 178.727 29.2849 178.31 29.2849 177.818H30.5932C30.5932 178.06 30.6485 178.268 30.7593 178.445C30.873 178.621 31.0278 178.759 31.2238 178.858C31.4227 178.955 31.65 179.003 31.9057 179.003C32.1841 179.003 32.4199 178.945 32.613 178.828C32.8091 178.709 32.9582 178.534 33.0605 178.304C33.1628 178.074 33.2139 177.793 33.2139 177.46L33.2139 171.273ZM37.1802 180H35.7824L38.9231 171.273H40.4444L43.585 180H42.1873L39.7199 172.858H39.6517L37.1802 180ZM37.4145 176.582H41.9486L41.9486 177.69H37.4145L37.4145 176.582ZM51.8893 171.273L51.8893 180H50.6791L46.243 173.599H46.162L46.162 180H44.8453L44.8453 171.273H46.064L50.5044 177.682H50.5853L50.5853 171.273H51.8893Z" fill="#F1EEFF"/>
    <path d="M85.7144 27C85.7144 24.2386 87.9529 22 90.7144 22C93.4758 22 95.7144 24.2386 95.7144 27L95.7144 159C95.7144 161.761 93.4758 164 90.7144 164C87.9529 164 85.7144 161.761 85.7144 159L85.7144 27Z" fill="url(#paint2_linear_676_6064)"/>
    <g filter="url(#filter1_d_676_6064)">
      <path d="M99.7144 93C99.7144 90.2386 101.953 88 104.714 88C107.476 88 109.714 90.2386 109.714 93L109.714 159C109.714 161.761 107.476 164 104.714 164C101.953 164 99.7144 161.761 99.7144 159L99.7144 93Z" fill="url(#paint3_linear_676_6064)" shape-rendering="crispEdges"/>
    </g>
    <path d="M87.6165 180L87.6165 171.273H93.0285L93.0285 172.406H88.9333L88.9333 175.065H92.6407L92.6407 176.195H88.9333L88.9333 180H87.6165ZM94.6478 180L94.6478 171.273H100.119L100.119 172.406H95.9645L95.9645 175.065H99.8339L99.8339 176.195H95.9645L95.9645 178.866H100.17L100.17 180H94.6478ZM101.878 180L101.878 171.273H105.074C105.694 171.273 106.206 171.375 106.613 171.58C107.019 171.781 107.323 172.055 107.525 172.402C107.726 172.746 107.827 173.134 107.827 173.565C107.827 173.929 107.76 174.236 107.627 174.486C107.493 174.733 107.314 174.932 107.09 175.082C106.868 175.23 106.624 175.338 106.357 175.406L106.357 175.491C106.647 175.506 106.929 175.599 107.205 175.773C107.483 175.943 107.713 176.186 107.895 176.501C108.077 176.817 108.168 177.2 108.168 177.652C108.168 178.098 108.063 178.499 107.853 178.854C107.645 179.206 107.324 179.486 106.89 179.693C106.455 179.898 105.9 180 105.223 180H101.878ZM103.195 178.871H105.096C105.726 178.871 106.178 178.749 106.451 178.504C106.723 178.26 106.86 177.955 106.86 177.588C106.86 177.312 106.79 177.06 106.651 176.83C106.512 176.599 106.313 176.416 106.054 176.28C105.799 176.143 105.495 176.075 105.142 176.075H103.195L103.195 178.871ZM103.195 175.048H104.959C105.255 175.048 105.52 174.991 105.756 174.878C105.995 174.764 106.184 174.605 106.323 174.401C106.465 174.193 106.536 173.949 106.536 173.668C106.536 173.307 106.409 173.004 106.157 172.76C105.904 172.516 105.516 172.393 104.993 172.393H103.195L103.195 175.048Z" fill="#F1EEFF"/>
    <path d="M138.572 5C138.572 2.23857 140.81 -4.06152e-09 143.572 -9.07165e-09C146.333 -1.40818e-08 148.572 2.23858 148.572 5L148.572 159C148.572 161.761 146.333 164 143.572 164C140.81 164 138.572 161.761 138.572 159L138.572 5Z" fill="url(#paint4_linear_676_6064)"/>
    <g filter="url(#filter2_d_676_6064)">
      <path d="M152.572 93C152.572 90.2386 154.81 88 157.572 88C160.333 88 162.572 90.2386 162.572 93L162.572 159C162.572 161.761 160.333 164 157.572 164C154.81 164 152.572 161.761 152.572 159L152.572 93Z" fill="url(#paint5_linear_676_6064)" shape-rendering="crispEdges"/>
    </g>
    <path d="M142.077 171.273H143.675L146.454 178.057H146.556L149.334 171.273H150.932L150.932 180H149.679L149.679 173.685H149.599L147.025 179.987H145.985L143.411 173.68H143.33L143.33 180H142.077L142.077 171.273ZM153.584 180H152.186L155.327 171.273H156.848L159.989 180H158.591L156.124 172.858H156.056L153.584 180ZM153.818 176.582H158.352L158.352 177.69H153.818L153.818 176.582ZM161.249 180L161.249 171.273H164.36C165.036 171.273 165.597 171.389 166.043 171.622C166.492 171.855 166.827 172.178 167.049 172.589C167.27 172.999 167.381 173.472 167.381 174.009C167.381 174.543 167.269 175.013 167.045 175.419C166.823 175.822 166.488 176.136 166.039 176.361C165.593 176.585 165.032 176.697 164.356 176.697H161.999L161.999 175.564H164.236C164.662 175.564 165.009 175.503 165.276 175.381C165.546 175.259 165.743 175.081 165.868 174.848C165.993 174.615 166.056 174.335 166.056 174.009C166.056 173.679 165.992 173.393 165.864 173.152C165.739 172.911 165.542 172.726 165.272 172.598C165.005 172.467 164.654 172.402 164.219 172.402H162.566L162.566 180H161.249ZM165.557 176.062L167.714 180H166.214L164.1 176.062H165.557Z" fill="#F1EEFF"/>
    <path d="M199.428 16C199.428 13.2386 201.667 11 204.428 11C207.19 11 209.428 13.2386 209.428 16L209.428 159C209.428 161.761 207.19 164 204.428 164C201.667 164 199.428 161.761 199.428 159L199.428 16Z" fill="url(#paint6_linear_676_6064)"/>
    <g filter="url(#filter3_d_676_6064)">
      <path d="M213.428 47C213.428 44.2386 215.667 42 218.428 42C221.19 42 223.428 44.2386 223.428 47L223.428 159C223.428 161.761 221.19 164 218.428 164C215.667 164 213.428 161.761 213.428 159L213.428 47Z" fill="url(#paint7_linear_676_6064)" shape-rendering="crispEdges"/>
    </g>
    <path d="M201.218 180H199.821L202.961 171.273H204.482L207.623 180H206.225L203.758 172.858H203.69L201.218 180ZM201.453 176.582H205.987L205.987 177.69H201.453L201.453 176.582ZM208.883 180L208.883 171.273H211.994C212.673 171.273 213.236 171.396 213.682 171.643C214.128 171.891 214.461 172.229 214.683 172.658C214.905 173.084 215.015 173.564 215.015 174.098C215.015 174.635 214.903 175.118 214.679 175.547C214.457 175.973 214.122 176.311 213.673 176.561C213.227 176.808 212.666 176.932 211.99 176.932H209.851L209.851 175.815H211.871C212.3 175.815 212.648 175.741 212.915 175.594C213.182 175.443 213.378 175.239 213.503 174.98C213.628 174.722 213.69 174.428 213.69 174.098C213.69 173.768 213.628 173.476 213.503 173.22C213.378 172.964 213.18 172.764 212.91 172.619C212.643 172.474 212.291 172.402 211.854 172.402H210.2L210.2 180H208.883ZM216.559 180L216.559 171.273H219.67C220.346 171.273 220.907 171.389 221.353 171.622C221.802 171.855 222.137 172.178 222.359 172.589C222.58 172.999 222.691 173.472 222.691 174.009C222.691 174.543 222.579 175.013 222.355 175.419C222.133 175.822 221.798 176.136 221.349 176.361C220.903 176.585 220.342 176.697 219.666 176.697H217.309L217.309 175.564H219.546C219.973 175.564 220.319 175.503 220.586 175.381C220.856 175.259 221.053 175.081 221.178 174.848C221.303 174.615 221.366 174.335 221.366 174.009C221.366 173.679 221.302 173.393 221.174 173.152C221.049 172.911 220.852 172.726 220.582 172.598C220.315 172.467 219.964 172.402 219.529 172.402H217.876L217.876 180H216.559ZM220.867 176.062L223.024 180H221.524L219.41 176.062H220.867Z" fill="#F1EEFF"/>
    <path d="M256.286 5C256.286 2.23857 258.524 -4.09729e-09 261.286 -9.15155e-09C264.047 -1.42058e-08 266.286 2.23858 266.286 5L266.286 159C266.286 161.761 264.047 164 261.286 164C258.524 164 256.286 161.761 256.286 159L256.286 5Z" fill="url(#paint8_linear_676_6064)"/>
    <g filter="url(#filter4_d_676_6064)">
      <path d="M270.286 37C270.286 34.2386 272.524 32 275.286 32C278.047 32 280.286 34.2386 280.286 37L280.286 159C280.286 161.761 278.047 164 275.286 164C272.524 164 270.286 161.761 270.286 159L270.286 37Z" fill="url(#paint9_linear_676_6064)" shape-rendering="crispEdges"/>
    </g>
    <path d="M255.996 171.273H257.594L260.373 178.057H260.475L263.254 171.273H264.852L264.852 180H263.599L263.599 173.685H263.518L260.944 179.987H259.904L257.33 173.68H257.249L257.249 180H255.996L255.996 171.273ZM267.503 180H266.105L269.246 171.273H270.767L273.908 180H272.51L270.043 172.858H269.975L267.503 180ZM267.738 176.582H272.272L272.272 177.69H267.738L267.738 176.582ZM273.604 171.273H275.1L277.38 175.24H277.474L279.754 171.273H281.249L278.083 176.574L278.083 180H276.771L276.771 176.574L273.604 171.273Z" fill="#F1EEFF"/>
    <path d="M313.643 45C313.643 42.2386 315.881 40 318.643 40C321.404 40 323.643 42.2386 323.643 45L323.643 159C323.643 161.761 321.404 164 318.643 164C315.881 164 313.643 161.761 313.643 159L313.643 45Z" fill="url(#paint10_linear_676_6064)"/>
    <g filter="url(#filter5_d_676_6064)">
      <path d="M327.643 100.5C327.643 98.0147 329.658 96 332.143 96C334.628 96 336.643 98.0147 336.643 100.5L336.643 159.5C336.643 161.985 334.628 164 332.143 164C329.658 164 327.643 161.985 327.643 159.5L327.643 100.5Z" fill="url(#paint11_linear_676_6064)" shape-rendering="crispEdges"/>
    </g>
    <path d="M317.277 171.273H318.589L318.589 177.46C318.589 178.02 318.479 178.499 318.257 178.896C318.038 179.294 317.73 179.598 317.332 179.808C316.935 180.016 316.469 180.119 315.935 180.119C315.443 180.119 315.001 180.03 314.609 179.851C314.22 179.672 313.912 179.412 313.685 179.071C313.46 178.727 313.348 178.31 313.348 177.818H314.656C314.656 178.06 314.712 178.268 314.822 178.445C314.936 178.621 315.091 178.759 315.287 178.858C315.486 178.955 315.713 179.003 315.969 179.003C316.247 179.003 316.483 178.945 316.676 178.828C316.872 178.709 317.021 178.534 317.123 178.304C317.226 178.074 317.277 177.793 317.277 177.46L317.277 171.273ZM326.118 171.273H327.439L327.439 177.013C327.439 177.624 327.296 178.165 327.009 178.636C326.722 179.105 326.318 179.474 325.799 179.744C325.279 180.011 324.669 180.145 323.97 180.145C323.274 180.145 322.666 180.011 322.147 179.744C321.627 179.474 321.223 179.105 320.936 178.636C320.649 178.165 320.506 177.624 320.506 177.013L320.506 171.273H321.823L321.823 176.906C321.823 177.301 321.909 177.652 322.083 177.959C322.259 178.266 322.507 178.507 322.828 178.683C323.149 178.857 323.53 178.943 323.97 178.943C324.414 178.943 324.796 178.857 325.117 178.683C325.441 178.507 325.688 178.266 325.858 177.959C326.031 177.652 326.118 177.301 326.118 176.906L326.118 171.273ZM336.398 171.273L336.398 180H335.187L330.751 173.599H330.67L330.67 180H329.354L329.354 171.273H330.572L335.013 177.682H335.094L335.094 171.273H336.398Z" fill="#F1EEFF"/>
    <defs>
      <filter id="filter0_d_676_6064" x="32.8572" y="94" width="30" height="80" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_676_6064"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_676_6064" result="shape"/>
      </filter>
      <filter id="filter1_d_676_6064" x="89.7144" y="78" width="30" height="96" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_676_6064"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_676_6064" result="shape"/>
      </filter>
      <filter id="filter2_d_676_6064" x="142.572" y="78" width="30" height="96" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_676_6064"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_676_6064" result="shape"/>
      </filter>
      <filter id="filter3_d_676_6064" x="203.428" y="32" width="30" height="142" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_676_6064"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_676_6064" result="shape"/>
      </filter>
      <filter id="filter4_d_676_6064" x="260.286" y="22" width="30" height="152" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_676_6064"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_676_6064" result="shape"/>
      </filter>
      <filter id="filter5_d_676_6064" x="317.643" y="86" width="29" height="88" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_676_6064"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_676_6064" result="shape"/>
      </filter>
      <linearGradient id="paint0_linear_676_6064" x1="33.8572" y1="-9.21983e-09" x2="33.8572" y2="164" gradientUnits="userSpaceOnUse">
        <stop stop-color="#6AFFCD"/>
        <stop offset="1" stop-color="#6AFFCD" stop-opacity="0.4"/>
      </linearGradient>
      <linearGradient id="paint1_linear_676_6064" x1="47.8572" y1="104" x2="47.8572" y2="164" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF9CA5"/>
        <stop offset="1" stop-color="#FF9CA5" stop-opacity="0.5"/>
      </linearGradient>
      <linearGradient id="paint2_linear_676_6064" x1="90.7144" y1="22" x2="90.7144" y2="164" gradientUnits="userSpaceOnUse">
        <stop stop-color="#6AFFCD"/>
        <stop offset="1" stop-color="#6AFFCD" stop-opacity="0.4"/>
      </linearGradient>
      <linearGradient id="paint3_linear_676_6064" x1="104.714" y1="88" x2="104.714" y2="164" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF9CA5"/>
        <stop offset="1" stop-color="#FF9CA5" stop-opacity="0.5"/>
      </linearGradient>
      <linearGradient id="paint4_linear_676_6064" x1="143.572" y1="-9.07165e-09" x2="143.572" y2="164" gradientUnits="userSpaceOnUse">
        <stop stop-color="#6AFFCD"/>
        <stop offset="1" stop-color="#6AFFCD" stop-opacity="0.4"/>
      </linearGradient>
      <linearGradient id="paint5_linear_676_6064" x1="157.572" y1="88" x2="157.572" y2="164" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF9CA5"/>
        <stop offset="1" stop-color="#FF9CA5" stop-opacity="0.5"/>
      </linearGradient>
      <linearGradient id="paint6_linear_676_6064" x1="204.428" y1="11" x2="204.428" y2="164" gradientUnits="userSpaceOnUse">
        <stop stop-color="#6AFFCD"/>
        <stop offset="1" stop-color="#6AFFCD" stop-opacity="0.4"/>
      </linearGradient>
      <linearGradient id="paint7_linear_676_6064" x1="218.428" y1="42" x2="218.428" y2="164" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF9CA5"/>
        <stop offset="1" stop-color="#FF9CA5" stop-opacity="0.5"/>
      </linearGradient>
      <linearGradient id="paint8_linear_676_6064" x1="261.286" y1="-9.15156e-09" x2="261.286" y2="164" gradientUnits="userSpaceOnUse">
        <stop stop-color="#6AFFCD"/>
        <stop offset="1" stop-color="#6AFFCD" stop-opacity="0.4"/>
      </linearGradient>
      <linearGradient id="paint9_linear_676_6064" x1="275.286" y1="32" x2="275.286" y2="164" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF9CA5"/>
        <stop offset="1" stop-color="#FF9CA5" stop-opacity="0.5"/>
      </linearGradient>
      <linearGradient id="paint10_linear_676_6064" x1="318.643" y1="40" x2="318.643" y2="164" gradientUnits="userSpaceOnUse">
        <stop stop-color="#6AFFCD"/>
        <stop offset="1" stop-color="#6AFFCD" stop-opacity="0.4"/>
      </linearGradient>
      <linearGradient id="paint11_linear_676_6064" x1="332.143" y1="96" x2="332.143" y2="164" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF9CA5"/>
        <stop offset="1" stop-color="#FF9CA5" stop-opacity="0.5"/>
      </linearGradient>
    </defs>
  </svg>
</template>