<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.1866 10.9066H16.64C17.1457 10.9066 17.6306 10.7057 17.9882 10.3482C18.3458 9.99058 18.5466 9.50562 18.5466 8.99994C18.5466 8.49426 18.3458 8.00929 17.9882 7.65172C17.6306 7.29415 17.1457 7.09327 16.64 7.09327H15.3066C14.801 7.09327 14.316 6.89239 13.9584 6.53482C13.6009 6.17725 13.4 5.69228 13.4 5.1866C13.4 4.67969 13.6004 4.19334 13.9576 3.83365C14.3148 3.47396 14.7997 3.27012 15.3066 3.2666H19.76" stroke="#3E2CAF" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M16 0.733398V3.26673" stroke="#3E2CAF" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M16 10.9067V13.4534" stroke="#3E2CAF" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M23.64 23.6267C25.746 23.6267 27.4533 21.9194 27.4533 19.8133C27.4533 17.7073 25.746 16 23.64 16C21.5339 16 19.8267 17.7073 19.8267 19.8133C19.8267 21.9194 21.5339 23.6267 23.64 23.6267Z" stroke="#3E2CAF" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M17.3334 31.2668V30.0002C17.3334 29.1638 17.4983 28.3357 17.8188 27.5632C18.1392 26.7907 18.6089 26.089 19.2009 25.4982C19.7929 24.9075 20.4956 24.4393 21.2687 24.1204C22.0419 23.8016 22.8704 23.6384 23.7067 23.6402C25.3819 23.6577 26.9825 24.3355 28.1609 25.5264C29.3392 26.7172 30.0001 28.3249 30 30.0002V31.2668" stroke="#3E2CAF" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M8.35996 23.6267C10.466 23.6267 12.1733 21.9194 12.1733 19.8133C12.1733 17.7073 10.466 16 8.35996 16C6.25392 16 4.54663 17.7073 4.54663 19.8133C4.54663 21.9194 6.25392 23.6267 8.35996 23.6267Z" stroke="#3E2CAF" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M2 31.2668V30.0002C2 28.3134 2.67007 26.6957 3.8628 25.503C5.05553 24.3102 6.67322 23.6402 8.36 23.6402C9.19633 23.6384 10.0248 23.8016 10.798 24.1204C11.5711 24.4393 12.2738 24.9075 12.8658 25.4982C13.4578 26.089 13.9275 26.7907 14.2479 27.5632C14.5684 28.3357 14.7333 29.1638 14.7333 30.0002V31.2668" stroke="#3E2CAF" stroke-width="2" stroke-miterlimit="10"/>
  </svg>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
</style>