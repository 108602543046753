import { logger } from "@/utils/logger";

export const BASE_URI =`${location.protocol}//${location.host}`;

export const PATH_EMBED = "embed";
export const PATH_ERROR = "error";

export const PARAM_STEP = "st";
export const PARAM_ORIGINATING_URI = "ou";
export const PARAM_CFM_SESSION_TOKEN = "cs";
export const PARAM_ID = "id";
export const PARAM_RID = "rid";

export const KEY_CFM_SESSION_TOKEN = `cfm_${PARAM_CFM_SESSION_TOKEN}`;

export const STEP_VALUE_ATTEMPTED = "1";

export function redirect(url) {
  logger.debug(`Redirecting to ${url}`);
  window.location.href = url;
}

export function redirectWithPath(path: string) {
  if (!path.startsWith("/")) {
    path = `/${path}`;
  }
  if (window.location.pathname !== path) {
    redirect(`${BASE_URI}${path}`);
  }
}
