<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.10669 16H31.3334" stroke="#100749" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M17.3067 5.80005L7.10669 16L17.3067 26.2" stroke="#100749" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M2 5.80005V26.2" stroke="#100749" stroke-width="2" stroke-miterlimit="10"/>
  </svg>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
</style>