<template>
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 6.6665L13 6.6665M13 6.6665C13 8.04722 14.1193 9.1665 15.5 9.1665C16.8807 9.1665 18 8.04722 18 6.6665C18 5.28579 16.8807 4.1665 15.5 4.1665C14.1193 4.1665 13 5.28579 13 6.6665ZM8 13.3332L18 13.3332M8 13.3332C8 14.7139 6.88071 15.8332 5.5 15.8332C4.11929 15.8332 3 14.7139 3 13.3332C3 11.9525 4.11929 10.8332 5.5 10.8332C6.88071 10.8332 8 11.9525 8 13.3332Z" stroke="#6B56F3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
</style>