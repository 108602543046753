<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_919_2182)">
      <path d="M2.44995 17.7301H16.77C18.2896 17.7301 19.7471 17.1264 20.8217 16.0518C21.8963 14.9772 22.5 13.5198 22.5 12.0001V10.0901" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10"/>
      <path d="M7.22996 12.96L2.45996 17.73L7.22996 22.5" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10"/>
      <path d="M21.55 6.27002H7.23C6.47753 6.27002 5.73242 6.41823 5.03722 6.70619C4.34203 6.99415 3.71036 7.41622 3.17828 7.9483C2.10369 9.02288 1.5 10.4803 1.5 12V13.91" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10"/>
      <path d="M16.77 11.04L21.55 6.27L16.77 1.5" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10"/>
    </g>
    <defs>
      <clipPath id="clip0_919_2182">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
</style>