import { logger } from "@/utils/logger";

export function parseCfmWebContext<T>(json?: string): T | null {
  let result: T | null = null;
  try {
    result = json ? JSON.parse(json) as T : null;
  } catch (error) {
    logger.error(`Failed to parse context ${json}`);
  }
  return result;
}

export function isDevelopment(): boolean {
  return process.env.NODE_ENV === "development";
}