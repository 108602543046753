<template>
  <div class="card-quick-button container-fluid">
    <div class="row">
      <div class="col d-center-x align-items-start icon">
        <slot name="icon"></slot>
      </div>
    </div>
    <div class="row d-center flex-grow-1">
      <div class="col d-center">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
.container-fluid {
  display: flex;
  flex-direction: column;
}

.icon {
  min-height: 30px;
  margin-top: 0.5rem;
}
</style>