import { Data } from "@/data/generated/api";
import { logger } from "@/utils/logger";
import Cookies from "js-cookie";
import CFMBannoWebSessionResponse = Data.CFMBannoWebSessionResponse;
import { CFMContext } from "@/data/context";
import qs from "qs";
import { PARAM_CFM_SESSION_TOKEN } from "@/utils/uriUtils";

export type SessionModalDismissHandler = () => void;
export type SessionModalFinishHandler = () => void;

export function getSessionTokenFromQuery(): string | undefined {
  let queryString = window.location.search;
  if (queryString?.startsWith("?")) {
    queryString = queryString.substring(1);
  }
  const params = qs.parse(queryString);
  const cs = params[PARAM_CFM_SESSION_TOKEN];
  if (cs) {
    return typeof cs === "string" ? cs : qs.stringify(cs);
  }
}

export interface SessionExpiringTimeout {
  expiringTimeout: number,
  secondsRemainingAtExpiringTimeout: number
}

export class CFMSessionManager {
  private readonly cookieName: string;
  private readonly showModalWhenRemainingMillis: number;
  private readonly expiresAtSkewMillis: number;

  constructor(context: CFMContext) {
    const sessionConfig = context.web.config.session;
    this.cookieName = sessionConfig.cookieName;
    this.showModalWhenRemainingMillis = sessionConfig.showModalWhenRemainingMillis;
    this.expiresAtSkewMillis = sessionConfig.expiresAtSkewMillis;
  }

  public getSessionToken(): string | undefined {
    return Cookies.get(this.cookieName) || getSessionTokenFromQuery();
  }

  private setSessionToken(session: CFMBannoWebSessionResponse) {
    const cookie = Cookies.get(this.cookieName);
    if (!cookie || cookie !== session.tokenValue) {
      logger.debug(`[session] Setting ${this.cookieName}`);
      Cookies.set(this.cookieName, session.tokenValue);
    }
  }

  private calculateSessionModalInterval(session: CFMBannoWebSessionResponse): number | null {
    let millis = (session.expiresAt * 1000) - Date.now();
    if (millis <= 0) {
      logger.error(`[session] Cannot schedule session timeout (ms=${millis})`);
      return null;
    }
    if (millis > this.expiresAtSkewMillis) {
      millis -= this.expiresAtSkewMillis;
    }
    if (millis > this.showModalWhenRemainingMillis) {
      millis -= this.showModalWhenRemainingMillis;
    }
    return millis;
  }

  public calculateTimeout(session: CFMBannoWebSessionResponse): SessionExpiringTimeout | null {
    this.setSessionToken(session);

    const timeoutInterval = this.calculateSessionModalInterval(session);
    if (!timeoutInterval) {
      return null;
    }

    logger.debug(`[session] Modal at ${new Date(Date.now() + timeoutInterval)} (in ${timeoutInterval / 1000} seconds)`);

    return {
      expiringTimeout: timeoutInterval,
      secondsRemainingAtExpiringTimeout: this.showModalWhenRemainingMillis / 1000
    };
  }
}

