<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_919_679)">
      <path d="M22.5 6.27V9.14H1.5V6.27L12 1.5L22.5 6.27Z" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"/>
      <path d="M22.5 18.6799H1.5V22.4999H22.5V18.6799Z" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"/>
      <path d="M3.40991 9.13989V18.6799" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"/>
      <path d="M9.13989 9.13989V18.6799" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"/>
      <path d="M14.8601 9.13989V18.6799" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"/>
      <path d="M20.5901 9.13989V18.6799" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"/>
    </g>
    <defs>
      <clipPath id="clip0_919_679">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
</style>