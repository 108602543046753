<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 21.1812L10.64 13.5412L3 9.18115L21 3.18115L15 21.1812Z" stroke="#D0C9FF" stroke-width="1.5" stroke-miterlimit="10"/>
    <path d="M11 13.1812L16 8.18115" stroke="#D0C9FF" stroke-width="1.5" stroke-miterlimit="10"/>
  </svg>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
</style>