import { Data } from "@/data/generated/api";
import { logger } from "@/utils/logger";
import CFMBannoWebViewData = Data.CFMBannoWebViewData;
import { CFMContext } from "@/data/context";

const MOBILE_WEB_VIEW_TYPES: Set<Data.CFMBannoWebUAType> = new Set([
  "ANDROID_WEB_VIEW",
  "IOS_WEB_VIEW"
]);

const TOUCH_DEVICE_SMALL_WIDTH_BREAKPOINT = 770;
const WINDOW_MAXIMIZED_WIDTH_LEEWAY = 5;

function getPluginTypeClasses(type?: Data.CFMBannoWebPluginType | null): string[] {
    switch (type) {
        case "EXTERNAL": return ["plugin-external"];
        case "EMBEDDED": return ["plugin-embedded"];
    }
    logger.error(`Unknown plugin type ${type}`);
    return [];
}

function getClientTypeClasses(type?: Data.CFMBannoWebUAType | null): string[] {
    switch (type) {
        case "BROWSER": return ["client-browser"];
        case "ANDROID_WEB_VIEW": return ["client-webview", "client-webview-android"];
        case "IOS_WEB_VIEW": return ["client-webview", "client-webview-ios"];
    }
    logger.error(`Unknown client type ${type}`);
    return [];
}

export function getContextClasses(config?: CFMBannoWebViewData | null): string[] {
    return [
        ...getPluginTypeClasses(config?.plugin?.type),
        ...getClientTypeClasses(config?.client?.parsed?.type)
    ];
}

export function isTouchDevice() {
    return 'ontouchstart' in window;
}

export function isTouchDeviceSmall() {
    // This might not match all touch devices if the width is too large,
    // e.g. a laptop with a touchscreen or an iPad in landscape view, but
    // if there's enough screen real estate we probably want to show the
    // desktop view anyway.
    return isTouchDevice() && (window.innerWidth < TOUCH_DEVICE_SMALL_WIDTH_BREAKPOINT);
}

export function isMobileWebView(context?: Data.CFMBannoWebViewData): boolean {
    const type = context?.client?.parsed?.type;
    return type !== undefined && MOBILE_WEB_VIEW_TYPES.has(type);
}

function isWindowMaximizedHorizontally(windowWidth: number, screenWidth: number) {
    // Some phones like the S21 Ultra report a slightly smaller innerWidth even when
    // maximized because the screen is curved.
    return Math.abs(screenWidth - windowWidth) <= WINDOW_MAXIMIZED_WIDTH_LEEWAY;
}

export function shouldRedirectFromEmbedded(context: CFMContext): boolean {
    if (context.plugin.isMobileWebView) {
        // Attempt to detect if this is the embedded plugin view or full external view.
        const devicePixelRatio = window.devicePixelRatio || 1;
        switch (context.web.client.parsed.type) {
            case "ANDROID_WEB_VIEW":
                return isWindowMaximizedHorizontally(window.innerWidth, window.screen.width);
            case "IOS_WEB_VIEW":
                // // On iOS, screen.width apparently doesn't change when the device is rotated.
                if (window.matchMedia("(orientation: portrait)").matches) {
                    return isWindowMaximizedHorizontally(window.innerWidth, window.screen.width) ||
                      isWindowMaximizedHorizontally(window.innerWidth, window.screen.width * devicePixelRatio);
                } else {
                    // noinspection JSSuspiciousNameCombination
                    return isWindowMaximizedHorizontally(window.innerWidth, window.screen.height) ||
                      isWindowMaximizedHorizontally(window.innerWidth, window.screen.height * devicePixelRatio);
                }
            default:
                logger.error(`Unknown web view type ${context.web.client.parsed.type}`);
                return false;
        }
    } else {
        // Redirect if not running in an iFrame
        try {
            return window === window.top;
        } catch (error) {
            return false;
        }
    }
}
