<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_919_2207)">
      <path d="M12 12H22.5C22.5028 13.3781 22.233 14.7432 21.7061 16.0166C21.1792 17.2901 20.4057 18.4467 19.43 19.42L12 12ZM12 12V1.47998C10.273 1.48011 8.57271 1.9062 7.04976 2.72051C5.52682 3.53481 4.22824 4.71219 3.26908 6.14833C2.30992 7.58446 1.7198 9.235 1.55101 10.9537C1.38221 12.6724 1.63996 14.4062 2.3014 16.0015C2.96284 17.5968 4.00755 19.0043 5.34298 20.0994C6.67841 21.1944 8.26332 21.9431 9.95728 22.2792C11.6512 22.6153 13.4019 22.5284 15.0543 22.0262C16.7066 21.5239 18.2096 20.6219 19.43 19.4" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10"/>
      <path d="M15.8201 8.15976H19.1601C19.5393 8.15976 19.9031 8.0091 20.1712 7.74092C20.4394 7.47274 20.5901 7.10902 20.5901 6.72976C20.5901 6.3505 20.4394 5.98677 20.1712 5.7186C19.9031 5.45042 19.5393 5.29976 19.1601 5.29976H18.1601C17.7799 5.29712 17.4162 5.14424 17.1483 4.87447C16.8804 4.60471 16.7301 4.23994 16.7301 3.85976C16.73 3.66855 16.7683 3.47928 16.8426 3.30312C16.917 3.12697 17.0259 2.96751 17.1629 2.83419C17.3 2.70086 17.4624 2.59638 17.6405 2.52691C17.8187 2.45745 18.0089 2.42441 18.2001 2.42976H21.5501" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10"/>
      <path d="M18.6799 0.52002V2.43002" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10"/>
      <path d="M18.6799 8.16016V10.0702" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10"/>
    </g>
    <defs>
      <clipPath id="clip0_919_2207">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
</style>