<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_919_1187)">
      <path d="M20.59 12C20.591 11.473 20.5408 10.9472 20.44 10.43L22.53 9.23L19.66 4.23L17.58 5.43C16.7759 4.74864 15.8541 4.21995 14.86 3.87V1.5H9.13997V3.91C8.14584 4.25995 7.22403 4.78864 6.41997 5.47L4.33997 4.27L1.46997 9.27L3.55997 10.47C3.35994 11.5071 3.35994 12.5729 3.55997 13.61L1.46997 14.81L4.33997 19.81L6.41997 18.61C7.22403 19.2914 8.14584 19.82 9.13997 20.17V22.5H14.86V20.09C15.8541 19.74 16.7759 19.2114 17.58 18.53L19.66 19.73L22.53 14.73L20.44 13.53C20.5382 13.0259 20.5885 12.5136 20.59 12Z" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10"/>
      <path d="M11.9999 15.8202C14.1097 15.8202 15.8199 14.1099 15.8199 12.0002C15.8199 9.89045 14.1097 8.18018 11.9999 8.18018C9.8902 8.18018 8.17993 9.89045 8.17993 12.0002C8.17993 14.1099 9.8902 15.8202 11.9999 15.8202Z" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10"/>
    </g>
    <defs>
      <clipPath id="clip0_919_1187">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
</style>