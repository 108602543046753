<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_919_2196)">
      <path d="M4.36011 15.8198H6.27011" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10"/>
      <path d="M17.73 15.8198H19.64" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10"/>
      <path d="M22.5 9.14014H1.5V22.5001H22.5V9.14014Z" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"/>
      <path d="M3.40991 5.31982H20.5899" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"/>
      <path d="M5.32007 1.5H18.6801" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"/>
      <path d="M11.9999 19.64C14.1097 19.64 15.8199 17.9297 15.8199 15.82C15.8199 13.7103 14.1097 12 11.9999 12C9.8902 12 8.17993 13.7103 8.17993 15.82C8.17993 17.9297 9.8902 19.64 11.9999 19.64Z" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"/>
      <path d="M4.36011 15.8198H6.27011" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10"/>
      <path d="M17.73 15.8198H19.64" stroke="#6B56F3" stroke-width="1.5" stroke-miterlimit="10"/>
    </g>
    <defs>
      <clipPath id="clip0_919_2196">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
</style>