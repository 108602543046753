<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.9996 25.3334C19.9996 27.5426 18.2088 29.3334 15.9996 29.3334C13.7905 29.3334 11.9996 27.5426 11.9996 25.3334M18.395 8.31816C18.9756 7.71827 19.333 6.90091 19.333 6.00008C19.333 4.15913 17.8406 2.66675 15.9996 2.66675C14.1587 2.66675 12.6663 4.15913 12.6663 6.00008C12.6663 6.90091 13.0236 7.71827 13.6043 8.31816M23.9996 14.9334C23.9996 13.0946 23.1568 11.3311 21.6565 10.0308C20.1562 8.73056 18.1214 8.00008 15.9996 8.00008C13.8779 8.00008 11.8431 8.73056 10.3428 10.0308C8.84248 11.3311 7.99963 13.0946 7.99963 14.9334C7.99963 17.9758 7.24514 20.2009 6.30371 21.793C5.23075 23.6076 4.69426 24.5149 4.71545 24.7316C4.73969 24.9796 4.78432 25.0578 4.98541 25.2049C5.16119 25.3334 6.04431 25.3334 7.81055 25.3334H24.1887C25.9549 25.3334 26.8381 25.3334 27.0138 25.2049C27.2149 25.0578 27.2596 24.9796 27.2838 24.7316C27.305 24.5149 26.7685 23.6076 25.6955 21.793C24.7541 20.2009 23.9996 17.9758 23.9996 14.9334Z" stroke="#181A37" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
</style>