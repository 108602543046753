const CURRENCY_FORMAT = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

export function toNumber(value: string | number) {
  if (typeof value === "number") {
    return value;
  }
  return Number(value.replaceAll(/[$,]/g, ""));
}

export function formatCurrency(value: number | string): string {
  return CURRENCY_FORMAT.format(
    typeof value === "string" ? toNumber(value) : value
  );
}
