import { InjectionKey, Ref } from "vue";
import { Data } from "@/data/generated/api";
import { Emitter, EventType } from "mitt";

interface CFMPluginContext {
  isEmbedded: boolean,
  isTouchDevice: boolean,
  isTouchDeviceSmall: boolean,
  isMobileWebView: boolean
}

interface CFMContext {
  web: Data.CFMBannoWebViewData
  plugin: CFMPluginContext
}

interface CFMNotificationContext {
  emitter: Emitter<Record<EventType, unknown>>
}

const cfmContextKey: InjectionKey<Ref<CFMContext>> = Symbol();
const navigationContextKey: InjectionKey<Emitter<Record<EventType, unknown>>> = Symbol();
const notificationContextKey: InjectionKey<Ref<CFMNotificationContext>> = Symbol();

export {
  CFMContext,
  CFMPluginContext,
  CFMNotificationContext,
  cfmContextKey,
  navigationContextKey,
  notificationContextKey
}
