<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_697_14034)">
      <rect width="48" height="48" rx="24" fill="white"/>
      <rect x="14" y="8" width="20" height="20" rx="10" fill="#1EA9AD"/>
      <rect x="-12" y="32" width="72" height="72" rx="36" fill="#1EA9AD"/>
    </g>
    <defs>
      <clipPath id="clip0_697_14034">
        <rect width="48" height="48" rx="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>